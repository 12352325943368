import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

import { CustomSelectV2 } from '@/components';

import { EmailsAutocomplete } from './components/EmailsAutocomplete';
import { InviteErrorTip } from './components/InviteErrorTip';
import { InviteWarningTip } from './components/InviteWarningTip';
import { useInviteModalViewModel } from './hooks/useInviteModalViewModel';

import styles from './styles.module.scss';

type InviteModalProps = {
  open: boolean;
  onClose(): void;
};

const ModalBody: React.FC<InviteModalProps> = ({ onClose }) => {
  const {
    emails,
    emailsError,
    handleEmailsChange,
    role,
    roleOptions,
    handleRoleChange,
    handleSubmit,
    submitDisabled,
    inviteError,
    inviteWarning,
  } = useInviteModalViewModel({
    onClose,
  });

  const { t } = useTranslation('settings');

  return (
    <Paper
      className={styles.Paper}
      component="form"
      onSubmit={(e): void => {
        e.preventDefault();
        handleSubmit();
      }}
      aria-disabled={submitDisabled}
    >
      <Typography className={styles.Title}>
        {t('teamManagement.inviteDialog.title')}
      </Typography>
      <Typography className={styles.Subtitle}>
        {t('teamManagement.inviteDialog.subtitle')}
      </Typography>
      <Box className={styles.InputWrapper}>
        <EmailsAutocomplete
          values={emails}
          onChange={handleEmailsChange}
          error={emailsError}
        />
      </Box>
      <Box className={styles.InputWrapper}>
        <Typography>{t('teamManagement.inviteDialog.rolesInputTitle')}</Typography>
        <CustomSelectV2
          options={roleOptions}
          MenuProps={{
            ListProps: {
              classes: { root: styles.List },
            },
          }}
          value={role}
          onChange={handleRoleChange}
        />
      </Box>
      {inviteError ? <InviteErrorTip error={inviteError} /> : null}
      {inviteWarning ? (
        <InviteWarningTip
          limit={inviteWarning.metadata?.limit ?? 1}
          type={'ActionChargeable'}
        />
      ) : null}
      <Box className={styles.ButtonsWrapper}>
        <Button variant="text" color="info" onClick={onClose}>
          {t('teamManagement.inviteDialog.buttonCancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitDisabled}
        >
          {t('teamManagement.inviteDialog.buttonSendInvite')}
        </Button>
      </Box>
    </Paper>
  );
};

export const InviteModal: React.FC<InviteModalProps> = (props) => {
  return (
    <Modal {...props} keepMounted={false}>
      <ModalBody {...props} />
    </Modal>
  );
};
