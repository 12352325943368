import { FC, useEffect } from 'react';
import { Stack } from '@mui/material';

import { useAnalytics } from '@/features/system/analytics';

import { BillingHistory } from './components/BillingHistory';
import { PaymentDetails } from './components/PaymentDetails';
import { SubscriptionOverview } from './components/SubscriptionOverview';
import { SuccessSubscriptionModal } from './components/SuccessSubscriptionModal';

import styles from './styles.module.scss';

export const Billing: FC = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackViewSubscriptionPage();
  }, []);

  return (
    <Stack className={styles.wrapper}>
      <SubscriptionOverview />
      <PaymentDetails />
      <BillingHistory />
      <SuccessSubscriptionModal />
    </Stack>
  );
};
