import { map } from 'rxjs';

import { useInjection } from '@/ioc';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { IAccountEntity, useAccount } from '@/features/common/account';
import { Permission, usePermissions } from '@/features/common/permissions';
import type { ITeamMemberEntity } from '@/features/settings';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { ITeamMemberUseCase } from '../../../domain/abstractions/ITeamMemberUseCase';

type UseTeamMembersTableViewModel = () => {
  teamMembers: ObservableResult<ITeamMemberEntity[], unknown, ITeamMemberEntity[]>;
  canManageTeamMembers: boolean;
  account: Nullable<IAccountEntity>;
  isLoading: boolean;
};

export const useTeamMembersTableViewModel: UseTeamMembersTableViewModel = () => {
  const { hasPermissions } = usePermissions();
  const canManageTeamMembers = hasPermissions(Permission.CanManageMembers);
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );
  const teamMembers = useObservableResult(
    () =>
      teamMemberUseCase
        .getTeamMembers()
        .pipe(map((members) => members.sort((a, b) => a.email.localeCompare(b.email)))),
    {
      deps: [],
      defaultData: [],
    },
  );
  const { account } = useAccount();

  return {
    teamMembers,
    canManageTeamMembers,
    account,
    isLoading: teamMembers.isLoading,
  };
};
