import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { BillingBanner } from '@/features/common/billing';
import { OnboardingWidget } from '@/features/common/onboarding';
import { HasPermission, Permission } from '@/features/common/permissions';
import {
  ProspectTaskNotificationWidget,
  ProspectTaskProgressProvider,
} from '@/features/common/prospectTask';
import { WorkspaceInvitation } from '@/features/common/workspace';
import { RewardsHandler } from '@/features/rewards';
import { HelpCenterWidget } from '@/features/system/helpCenter';

import { Header } from '@/components';

import styles from './styles.module.scss';

export const AuthenticatedLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ProspectTaskProgressProvider>
      <Box className={styles.wrapper}>
        <Header />
        <BillingBanner isGlobal />
        <Box className={styles.contentWrapper}>{children}</Box>
        <WorkspaceInvitation />
        <OnboardingWidget />
        <HelpCenterWidget />
        <HasPermission permission={Permission.CanViewProspectTaskProgress}>
          <ProspectTaskNotificationWidget />
        </HasPermission>
        <RewardsHandler />
      </Box>
    </ProspectTaskProgressProvider>
  );
};
