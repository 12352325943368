import { useMemo, useState } from 'react';

import { useContacts } from '@/features/common/contact';

import { useTableColumns } from './hooks';

type QuerySortParams = {
  field: 'name' | 'job_title' | 'company_name' | 'created_at' | 'assigned_to';
  sort: 'asc' | 'desc';
};

type SortableTableColField = 'name' | 'jobTitle' | 'company' | 'date' | 'contactOwner';

type TableSortItem = {
  field: SortableTableColField;
  sort: 'asc' | 'desc';
};

const mapTableSortItemToQuerySortParams = (item: TableSortItem): QuerySortParams => {
  const sortFieldsMap: {
    [key in SortableTableColField]: QuerySortParams['field'];
  } = {
    name: 'name',
    jobTitle: 'job_title',
    company: 'company_name',
    date: 'created_at',
    contactOwner: 'assigned_to',
  };

  return {
    field: sortFieldsMap[item.field],
    sort: item.sort,
  };
};

export const useDashboardTableViewModel = () => {
  const [sort, setSort] = useState<TableSortItem>({
    field: 'date',
    sort: 'desc',
  });
  const [pageSize] = useState(50);

  const params: URLSearchParams = useMemo(() => {
    const result = new URLSearchParams();
    const { field, sort: sortDirection } = mapTableSortItemToQuerySortParams(sort);

    result.set('start', '0');
    result.set('length', String(pageSize));
    result.set('sort_by', field || 'created_at');
    result.set('sort_dir', sortDirection || 'desc');
    result.set('stream', '1');

    return result;
  }, [sort, pageSize]);
  const queryString = params.toString();

  const contacts = useContacts(queryString, true);

  const columns = useTableColumns();

  const handleSortChange = (sortData: TableSortItem[]): void => {
    setSort(() => (sortData.length > 0 ? sortData[0] : { field: 'date', sort: 'desc' }));
  };

  return {
    columns,
    contacts,
    queryString,
    handleSortChange,
  };
};
