import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';

import { PlanType } from '@/features/common/billing';

import { HelpIcon } from '@/components/Icons';

import { PlanFeatureCategory } from '../../types';
import { unsupported } from '../../utils';

import { useComparePlansTableViewModel } from './ComparePlansTableViewModel';
import { FeatureSupportLevelCell } from './components';

import styles from './styles.module.scss';

type ComparePlansTableProps = {
  showPlanNames?: boolean;
  planTypes: PlanType[];
  planFeatureCategory: PlanFeatureCategory;
};

export const ComparePlansTable: FC<ComparePlansTableProps> = ({
  showPlanNames,
  planTypes,
  planFeatureCategory,
}) => {
  const { t } = useTranslation('plans');

  const { features, title, icon } = useComparePlansTableViewModel({
    planFeatureCategory,
  });

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHead}>
          <div
            className={clsx(styles.tableRow, {
              [styles.withoutColumns]: !showPlanNames,
            })}
          >
            <div className={clsx(styles.tableCell, styles.title)}>
              <div className={styles.iconCategory}>{icon}</div>
              <span>{title}</span>
            </div>
            {showPlanNames
              ? planTypes.map((planType) => (
                  <div className={styles.tableCell} key={planType}>
                    <span>{t(`plans.${planType}.name`)}</span>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className={styles.tableBody}>
          {features.map((feature, index) => (
            <div
              className={clsx(styles.tableRow, {
                [styles.isOdd]: (index + 1) % 2 === 1,
                [styles.isEven]: (index + 1) % 2 === 0,
              })}
              key={feature.title}
            >
              <div className={clsx(styles.tableCell, styles.title)}>
                <span>{feature.title}</span>
                <Tooltip
                  title={<Typography className={styles.hint}>{feature.hint}</Typography>}
                  placement="bottom"
                >
                  <div>
                    <HelpIcon className={styles.iconHelp} />
                  </div>
                </Tooltip>
              </div>
              {planTypes.map((planType) => (
                <div className={styles.tableCell} key={planType}>
                  <FeatureSupportLevelCell
                    level={feature.supportByPlan[planType] ?? unsupported()}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
