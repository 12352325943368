import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useInjection } from '@/ioc';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { UserRole } from '@/features/common/account';
import { WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { TeamMemberInvitaitionRequireChargeError } from '@/features/settings/features/teamManagement/domain/errors/TeamMemberInvitaitionRequireChargeError';
import { TeamMemberInviteGiftPlanLimitationError } from '@/features/settings/features/teamManagement/domain/errors/TeamMemberInviteGiftPlanLimitationError';

import { useConfirmationModal } from '@/components';

import { ITeamMemberUseCase } from '../../../../../domain/abstractions/ITeamMemberUseCase';

const roleTitleTranslationKeys = {
  [UserRole.Owner]: 'owner',
  [UserRole.Admin]: 'admin',
  [UserRole.Manager]: 'manager',
  [UserRole.Member]: 'user',
};

export const useRoleManagerButtonViewModel = (
  email: string,
): {
  menu: {
    anchorEl: HTMLButtonElement | null;
    options: {
      label: string;
      description: string;
      onClick: () => void;
    }[];
    open: (e: React.MouseEvent<HTMLButtonElement>) => void;
    close: () => void;
  };
} => {
  const { t } = useTranslation('settings');
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );
  const { openModal } = useConfirmationModal();

  const options = [
    {
      label: t('teamManagement.role.admin.title'),
      description: t('teamManagement.role.admin.description'),
      onClick: (): void => {
        updateUserRole(UserRole.Admin);
      },
    },
    {
      label: t('teamManagement.role.user.title'),
      description: t('teamManagement.role.user.description'),
      onClick: (): void => {
        updateUserRole(UserRole.Member);
      },
    },
    {
      label: t('teamManagement.role.manager.title'),
      description: t('teamManagement.role.manager.description'),
      onClick: (): void => {
        updateUserRole(UserRole.Manager);
      },
    },
  ];

  const menu = {
    anchorEl,
    options,
    open: (e: React.MouseEvent<HTMLButtonElement>): void => setAnchorEl(e.currentTarget),
    close: (): void => setAnchorEl(null),
  };

  async function updateUserRole(role: UserRole): Promise<void> {
    try {
      await teamMemberUseCase.assertRoleUpdate({ email, role });
      await teamMemberUseCase.updateTeamMemberRole({ email, role });
      menu.close();
    } catch (error) {
      if (error instanceof TeamMemberInvitaitionRequireChargeError) {
        openModal({
          title: t('teamManagement.roleChangeConfirmationModal.title'),
          description: (
            <Typography color="common" variant="h3">
              <Trans
                t={t}
                i18nKey="teamManagement.roleChangeConfirmationModal.description"
                values={{
                  role: t(`teamManagement.role.${roleTitleTranslationKeys[role]}.title`),
                  email,
                }}
                components={{
                  b: <b />,
                }}
              />
            </Typography>
          ),
          buttonText: t('teamManagement.roleChangeConfirmationModal.confirm'),
          handleAccept: async () => {
            await teamMemberUseCase.updateTeamMemberRole({ email, role });
            menu.close();
          },
        });
        return;
      }
      if (
        error instanceof WorkspaceSeatsLimitError ||
        error instanceof TeamMemberInviteGiftPlanLimitationError
      ) {
        enqueueSnackbar({
          variant: 'error',
          message: t('teamManagement.worspaceSeatsLimitationError.title'),
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
      menu.close();
    }
  }

  return {
    menu,
  };
};
