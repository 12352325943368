import * as amplitude from '@amplitude/analytics-browser';
import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import { combineLatest, distinctUntilChanged, filter, pairwise } from 'rxjs';

import {
  ACCOUNT_TYPES,
  APP_CONFIG_TYPES,
  AUTH_TYPES,
  WORKSPACE_TYPES,
} from '@/ioc/types';

import type { IAccountEntity, IAccountRepository } from '@/features/common/account';
import { AuthStatus, type IAuthRepository } from '@/features/common/auth';
import type { IWorkspaceEntity, IWorkspaceRepository } from '@/features/common/workspace';

import type { IAmplitudeRepository } from '../domain';

import type { AppConfig } from '@/config/AppConfig';

@injectable()
export class AmplitudeRepository implements IAmplitudeRepository {
  constructor(
    @inject(AUTH_TYPES.AuthRepository)
    private readonly authRepositorty: IAuthRepository,

    @inject(ACCOUNT_TYPES.AccountRepository)
    private readonly accountRepository: IAccountRepository,

    @inject(WORKSPACE_TYPES.WorkspaceRepository)
    private readonly workspaceRepository: IWorkspaceRepository,

    @inject(APP_CONFIG_TYPES.AppConfig)
    private readonly appConfig: AppConfig,
  ) {
    this.init();
    this.listenSignOut();
    this.listenAccountToIdentifyUser();
    this.listenMetadataToIdentifyEvent();
  }

  private init(): void {
    amplitude.init(this.appConfig.amplitude.apiKey, {
      defaultTracking: {
        sessions: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
      },
      appVersion: this.appConfig.version,
    });
  }

  private listenAccountToIdentifyUser(): void {
    this.accountRepository
      .getAccount()
      .pipe(
        filter((account) => !!account),
        distinctUntilChanged((prev, current) => prev.uuid === current.uuid),
      )
      .subscribe((account) => {
        amplitude.setUserId(account.uuid);
      });
  }

  private listenSignOut(): void {
    this.authRepositorty
      .getAuthStatus()
      .pipe(
        pairwise(),
        filter(
          ([previous, current]) =>
            previous === AuthStatus.Authorized && current === AuthStatus.Unauthorized,
        ),
      )
      .subscribe(() => {
        amplitude.reset();
      });
  }

  private listenMetadataToIdentifyEvent(): void {
    combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
    })
      .pipe(
        filter(({ account, workspace }) => !!account && !!workspace),
        distinctUntilChanged(
          (
            prev: { account: IAccountEntity; workspace: IWorkspaceEntity },
            current: { account: IAccountEntity; workspace: IWorkspaceEntity },
          ) => {
            return (
              prev.account.uuid === current.account.uuid &&
              prev.account.role === current.account.role &&
              prev.workspace.uuid === current.workspace.uuid &&
              prev.workspace.subscription.plan === current.workspace.subscription.plan &&
              prev.workspace.billableMembersCount ===
                current.workspace.billableMembersCount &&
              prev.workspace.approvedMembersCount ===
                current.workspace.approvedMembersCount
            );
          },
        ),
      )
      .subscribe(
        ({
          account,
          workspace,
        }: {
          account: IAccountEntity;
          workspace: IWorkspaceEntity;
        }) => {
          const identifyEvent = new amplitude.Identify();

          identifyEvent.set('email', account.email);

          identifyEvent.set('account_id', workspace.uuid);
          identifyEvent.set('paid_user', workspace.subscription.paid);
          identifyEvent.set('plan_type', workspace.subscription.plan);
          identifyEvent.set('is_gift', workspace.subscription.isGift);

          identifyEvent.set(
            'account_number_of_paid_users',
            workspace.billableMembersCount,
          );
          identifyEvent.set('account_number_of_users', workspace.approvedMembersCount);
          identifyEvent.set('account_role', account.role);

          identifyEvent.setOnce(
            'sign_up_date',
            dayjs(account.createdAt).format('YYYY-MM-DD'),
          );
          identifyEvent.setOnce('sign_up_provider', account.providers[0]);
          identifyEvent.set(
            'powerlead_community',
            workspace.subscription.plan === 'free_plus_monthly',
          );

          identifyEvent.set('credits_used', account.currentCreditsUsed);
          identifyEvent.set('total_credits_used', account.totalCreditsUsed);
          identifyEvent.set('total_search_count', account.totalSearchesCount);
          identifyEvent.set('total_results_count', account.totalResultsCount);

          amplitude.identify(identifyEvent);
        },
      );
  }

  public trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    amplitude.track(eventType, eventOptions);
  }
}
