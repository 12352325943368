import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES, GET_FREE_CREDITS_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IAccountRepository, IAccountUseCase } from '@/features/common/account';
import { AuthIdentityProvider } from '@/features/common/auth';
import type { IWorkspaceRepository } from '@/features/common/workspace';
import {
  IGetFreeCreditsRepository,
  IGetFreeCreditsUseCase,
} from '@/features/getFreeCredits';

@injectable()
export default class GetFreeCreditsUseCase implements IGetFreeCreditsUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountUseCase: IAccountUseCase;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(GET_FREE_CREDITS_TYPES.GetFreeCreditsRepository)
  private getFreeCreditsRepository: IGetFreeCreditsRepository;

  hasUserReceivedFreeCredits(): Observable<boolean> {
    return this.workspaceRepository
      .getCurrentWorkspaceSubscription()
      .pipe(map((s) => s?.plan === 'free_plus_monthly'));
  }

  async provideContacts(
    selectedProvider?: AuthIdentityProvider.google | AuthIdentityProvider.microsoft,
  ): Promise<void> {
    const provider = await firstValueFrom(this.getFederatedIdentityProvider());
    const computedProvider = this.computeIdentityProvider(provider, selectedProvider);

    const requestContacts =
      computedProvider === AuthIdentityProvider.google
        ? this.requestGoogleContacts
        : this.requestMicrosoftContacts;

    const { accessToken, refreshToken } = await requestContacts(provider);

    const req = { accessToken, refreshToken, provider: computedProvider };

    if (!accessToken) {
      throw new Error('Failed to get access token');
    }

    return this.accountRepository.provideContacts(req);
  }

  getFederatedIdentityProvider(): Observable<AuthIdentityProvider | null> {
    return this.accountUseCase.getIdentityProviders().pipe(
      map((providers) => {
        return providers.find((p) => this.isFederatedIdentityProvider(p)) ?? null;
      }),
    );
  }

  private computeIdentityProvider(
    provider: AuthIdentityProvider | null,
    selectedProvider?: AuthIdentityProvider.google | AuthIdentityProvider.microsoft,
  ): AuthIdentityProvider.google | AuthIdentityProvider.microsoft {
    if (
      selectedProvider === AuthIdentityProvider.google ||
      (!provider && !selectedProvider) ||
      (selectedProvider !== AuthIdentityProvider.microsoft &&
        provider === AuthIdentityProvider.google)
    ) {
      return AuthIdentityProvider.google;
    }

    return AuthIdentityProvider.microsoft;
  }

  private isFederatedIdentityProvider(provider: AuthIdentityProvider): boolean {
    return (
      provider === AuthIdentityProvider.google ||
      provider === AuthIdentityProvider.microsoft
    );
  }

  private requestGoogleContacts = async (
    accountProvider: AuthIdentityProvider | null,
  ): Promise<{ accessToken: string; refreshToken: string }> => {
    const account = await firstValueFrom(this.accountUseCase.getAccount());
    const provider = this.getFreeCreditsRepository.buildGoogleProvider();

    if (accountProvider) {
      provider.setCustomParameters({
        login_hint: account?.email || '',
      });
    }

    return this.getFreeCreditsRepository.requestScopes(provider, GoogleAuthProvider);
  };

  private requestMicrosoftContacts = (): Promise<{
    accessToken: string;
    refreshToken: string;
  }> => {
    const provider = this.getFreeCreditsRepository.buildMicrosoftProvider();
    return this.getFreeCreditsRepository.requestScopes(provider, OAuthProvider);
  };
}
