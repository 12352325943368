export enum CustomerMessagingEventKeys {
  INSERT_EMAIL = 'user_added_email_on_webform',
  SIGN_UP = 'sign_up',
  VIEW_PLANS_PAGE = 'user_viewed_plans_page',
  VIEW_PLANS_PAGE_TWICE = 'viewed_pricing_x2',
  VIEW_PAYMENT_DETAILS_PAGE = 'user_viewed_payment_details_page',
  PURCHASE_SUCCESSFUL = 'user_successfully_purchased',
  PAYMENT_FAILED = 'payment_failed',
  USER_REDEEM_5_FREE_CREDITS = 'user_redeem_5_free_credits',
}
