import { inject, injectable } from 'inversify';
import { combineLatest, distinctUntilKeyChanged, map, Observable } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import {
  BillingCycle,
  type IBillingUseCase,
  IProductEntity,
  PlanType,
} from '@/features/common/billing';
import type {
  ISubscriptionUseCase,
  IWorkspaceSubscriptionEntity,
} from '@/features/common/workspace';

import type { IPlansUseCase } from './abstractions/IPlansUseCase';
import type { GroupedProductMetadata } from './entities/GroupedProductMetadata';
import { ExpandProductMetadataBuilder } from './ProductMetadataBuilder/ExpandProductMetadataBuilder';
import { FreeProductMetadataBuilder } from './ProductMetadataBuilder/FreeProductMetadataBuilder';
import { ProductMetadataBuilder } from './ProductMetadataBuilder/ProductMetadataBuilder';
import { ProPlusProductMetaBuilder } from './ProductMetadataBuilder/ProPlusProductMetaBuilder';
import { ProProductMetadataBuilder } from './ProductMetadataBuilder/ProProductMetadataBuilder';
import { UnlimitedProductMetadataBuilder } from './ProductMetadataBuilder/UnlimitedProductMetadataBuilder';

interface PlanMetadataBuilderCostructor {
  new (
    products: IProductEntity[],
    subscription: IWorkspaceSubscriptionEntity,
    seats: number,
    cycle: BillingCycle,
    order: number,
  ): ProductMetadataBuilder;
}

@injectable()
export class PlansUseCase implements IPlansUseCase {
  @inject(BILLING_TYPES.BillingUseCase)
  private billingUseCase: IBillingUseCase;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  getGroupedProductMetadata(params?: {
    billingCycle: BillingCycle;
    seats: number;
  }): Observable<GroupedProductMetadata> {
    const seats = params?.seats ?? 1;
    const billingCycle = params?.billingCycle ?? BillingCycle.Monthly;
    return combineLatest({
      products: this.billingUseCase.getProducts(),
      subscription: this.subscriptionUseCase
        .getSubscription()
        .pipe(distinctUntilKeyChanged('plan')),
    }).pipe(
      map(({ products, subscription }) => {
        const planTypes = this.getOrderedPlanTypes(products);

        return planTypes.reduce((metadata, planType, order) => {
          const planTypeProducts = products.filter((p) => p.family === planType);
          const Builder = this.resolvePlanBuilder(planType);
          const builder = new Builder(
            planTypeProducts,
            subscription,
            seats,
            billingCycle,
            order,
          );

          metadata[planType] = builder.build();
          return metadata;
        }, {} satisfies GroupedProductMetadata);
      }),
    );
  }

  private resolvePlanBuilder(planType: PlanType): PlanMetadataBuilderCostructor {
    const builders = {
      [PlanType.Free]: FreeProductMetadataBuilder,
      [PlanType.Pro]: ProProductMetadataBuilder,
      [PlanType.ProPlus]: ProPlusProductMetaBuilder,
      [PlanType.Unlimited]: UnlimitedProductMetadataBuilder,
      [PlanType.Custom]: ExpandProductMetadataBuilder,
    };
    return builders[planType];
  }

  private getOrderedPlanTypes(products: IProductEntity[]): PlanType[] {
    const uniqFamilies = new Set(products.map((product) => product.family as PlanType));

    return [...uniqFamilies, PlanType.Custom]; // Custom is always the last family
  }
}
