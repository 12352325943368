import React, { useEffect } from 'react';
import { filter, first } from 'rxjs';

import { useAccountUseCase } from '@/features/common/account';

import { useHubspotUseCase } from './useHubspotUseCase';

import { useAppConfig } from '@/config';

export const Hubspot: React.FC = () => {
  const accountUseCase = useAccountUseCase();
  const hubspotUseCase = useHubspotUseCase();
  const appConfig = useAppConfig();

  useEffect(() => {
    if (appConfig.hubspot.syncAccountEnabled) {
      const sub = accountUseCase
        .getAccount()
        .pipe(
          filter((account) => !!account && !account?.settings.isImportedToHubspot),
          first(),
        )
        .subscribe(() => {
          hubspotUseCase.setupHubspotContact();
        });

      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  return null;
};
