import { FC } from 'react';

import { RewardsModal } from './components/RewardsModal';
import { useRewardsHandlerViewModel } from './hooks/useRewardsHandlerViewModel';

export const RewardsHandler: FC = () => {
  const { modal, error } = useRewardsHandlerViewModel();

  return <RewardsModal open={modal.isOpen} onClose={modal.onClose} error={error} />;
};
