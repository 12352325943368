import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserRole } from '@/features/common/account';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { Option } from '@/components';

import { useObservableResult } from '@/utils/rx';

import { TeamMemberInvitaitionRequireChargeError } from '../../../../domain/errors/TeamMemberInvitaitionRequireChargeError';
import { useTeamMembersUseCase } from '../../../hooks/useTeamMembersUseCase';
import type { InviteForm } from '../types';

import { useGetValidatedOnlyEmails } from './useGetValidatedOnlyEmails';
import { useInviteModalFormValidation } from './useInviteModalFormValidation';
import { useTeamMemberRoleOptions } from './useTeamMemberRoleOptions';

type UseInviteModalViewModel = (params: { onClose: () => void }) => {
  emails: string[];
  emailsError: InviteForm['formState']['errors']['emails'];
  handleEmailsChange: (emails: string[]) => void;
  role: UserRole;
  roleOptions: Option<UserRole>[];
  handleRoleChange: (role: UserRole) => void;
  handleSubmit: () => void;
  submitDisabled: boolean;
  inviteError?: Error;
  inviteWarning?: TeamMemberInvitaitionRequireChargeError;
};

export const useInviteModalViewModel: UseInviteModalViewModel = (params: {
  onClose: () => void;
}) => {
  const analytics = useAnalytics();
  const teamMemberUseCase = useTeamMembersUseCase();
  const { t } = useTranslation('settings');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const validation = useInviteModalFormValidation();
  const form = useForm({
    resolver: validation,
    defaultValues: {
      emails: [],
      role: UserRole.Member,
    },
    reValidateMode: 'onBlur',
  });
  const { emails = [], role } = form.watch();
  const roleOptions = useTeamMemberRoleOptions();

  const [isProcessing, setIsProcessing] = useState(false);
  const logger = useAppLogger();
  const onboardingUseCase = useOnboardingUseCase();

  const validEmails = useGetValidatedOnlyEmails(emails, form);

  const { error: inviteError } = useObservableResult(
    () => teamMemberUseCase.assertInvite(validEmails.map((email) => ({ email, role }))),
    { deps: [validEmails, role] },
  );

  const handleEmailsChange = (emails: string[]): void => {
    form.setValue('emails', emails, { shouldValidate: true });
  };

  const handleRoleChange = (role: UserRole): void => {
    form.setValue('role', role);
  };

  function handleSubmitForm(): void {
    form.handleSubmit(async ({ emails, role }) => {
      try {
        setIsProcessing(true);

        const members = emails.map((email) => ({ email, role }));

        await teamMemberUseCase.inviteTeamMembers(members);

        emails.forEach(() => analytics.trackAddTeamMember(role));

        onboardingUseCase.completeInviteTeamMemberStep();

        enqueueSnackbar(t('teamManagement.inviteDialog.successToast.title'), {
          variant: 'success',
          preventDuplicate: true,
          description: t('teamManagement.inviteDialog.successToast.subtitle'),
        });
        params.onClose();
      } catch (error) {
        enqueueSnackbar(tCommon('errors.errorOccurred'), {
          variant: 'error',
          preventDuplicate: true,
        });
        logger.error(error);
      } finally {
        setIsProcessing(false);
      }
    })();
  }

  const submitDisabled =
    !form.formState.isValid || !emails.length || isProcessing || !!inviteError;

  const getInviteError = (error: unknown): Error | undefined => {
    if (
      error instanceof Error &&
      !(error instanceof TeamMemberInvitaitionRequireChargeError) &&
      !isProcessing
    ) {
      return error;
    }
    return undefined;
  };

  const getInviteWarning = (
    error: unknown,
  ): TeamMemberInvitaitionRequireChargeError | undefined => {
    if (error instanceof TeamMemberInvitaitionRequireChargeError && !isProcessing) {
      return error;
    }
    return undefined;
  };

  return {
    emails,
    emailsError: form.formState.errors.emails,
    handleEmailsChange,
    role: role,
    roleOptions,
    handleRoleChange,
    handleSubmit: handleSubmitForm,
    submitDisabled,
    inviteError: getInviteError(inviteError),
    inviteWarning: getInviteWarning(inviteError),
  };
};
