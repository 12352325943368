import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc';
import { REWARDS_TYPES } from '@/ioc/types';

import { useAnalytics } from '@/features/system/analytics';
import {
  CustomerMessagingEventKeys,
  useCustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

import { useAsyncExecutor, useModalController } from '@/hooks';

import { RewardsAlreadyClaimedError } from '../../domain';
import type { IRewardsUseCase } from '../../domain/abstractions/IRewardsUseCase';

export const useRewardsHandlerViewModel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const modal = useModalController();
  const location = useLocation();
  const rewardSearchParam = searchParams.get('credits-reward');
  const analytics = useAnalytics();
  const rewardsUseCase = useInjection<IRewardsUseCase>(REWARDS_TYPES.RewardsUseCase);
  const customerMessagingUseCase = useCustomerMessagingUseCase();

  const claimCreditsAsyncExecutor = useAsyncExecutor(
    async () => {
      if (!rewardSearchParam) return;

      searchParams.delete('credits-reward');
      setSearchParams(searchParams);

      await rewardsUseCase.claimCredits();
      customerMessagingUseCase.track(
        CustomerMessagingEventKeys.USER_REDEEM_5_FREE_CREDITS,
      );
      analytics.trackRewardsRedeemSuccess();
      modal.onOpen();
    },
    {
      onError: (error) => {
        if (error instanceof RewardsAlreadyClaimedError) {
          analytics.trackRewardsRedeemError('already_receive');
        } else {
          analytics.trackRewardsRedeemError('network_error');
        }
        modal.onOpen();
      },
    },
  );

  useEffect(() => {
    if (
      !rewardSearchParam ||
      claimCreditsAsyncExecutor.isLoading ||
      location.pathname !== ROUTES.DASHBOARD
    )
      return;

    claimCreditsAsyncExecutor.asyncExecute();
  }, [rewardSearchParam, claimCreditsAsyncExecutor.isLoading, location.pathname]);

  return {
    modal,
    error: claimCreditsAsyncExecutor.error,
  };
};
