import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const RewardsModalLayout: FC<{
  title: string;
  description: string;
  button: {
    text: string;
    onClick: () => void;
  };
}> = ({ title, description, button }) => {
  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.description}>{description}</Typography>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={button.onClick}
      >
        {button.text}
      </Button>
    </Box>
  );
};
