import { inject, injectable } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import { IAmplitudeRepository, WalkthroughStep } from '../domain';

import { IFacebookPixelRepository } from './FacebookPixelRepository';
import { IGoogleTagManagerRepository } from './GoogleTagManagerRepository';
@injectable()
export class AnalyticsRepository {
  @inject(ANALYTICS_TYPES.AmplitudeRepository)
  private readonly amplitudeRepository: IAmplitudeRepository;

  @inject(ANALYTICS_TYPES.FacebookPixelRepository)
  private readonly facebookPixelRepository: IFacebookPixelRepository;

  @inject(ANALYTICS_TYPES.GoogleTagManagerRepository)
  private readonly googleTagManagerRepository: IGoogleTagManagerRepository;

  public trackUserProductWalkthrough(step: WalkthroughStep): void {
    this.amplitudeRepository.trackEvent('user_product_walkthrough', {
      step,
    });

    if (step === WalkthroughStep.installedExtension) {
      this.facebookPixelRepository.trackEvent('SubmitApplication');
    }
  }

  public trackMobileSignUpOpenChromeStore(): void {
    this.googleTagManagerRepository.trackEvent('Mobile_signup_open_chrome_store');
  }
}
