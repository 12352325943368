import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { RewardsAlreadyClaimedError } from '@/features/rewards/domain';

import { Modal } from '@/components';

import { openInNewTab } from '@/utils/openInNewTab';

import CloseIcon from '@/assets/icons/close-round.svg?react';

import { RewardsModalLayout } from './components/RewardsModalLayout';

import styles from './styles.module.scss';

const RewardsModalContent: FC<{ error?: Error; onClose: () => void }> = ({
  error,
  onClose,
}) => {
  const { t } = useTranslation('rewards', { keyPrefix: 'modal' });

  function onStartProspecting(): void {
    onClose();
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_URL);
  }

  switch (true) {
    case error instanceof RewardsAlreadyClaimedError:
      return (
        <RewardsModalLayout
          title={t('error.creditsClaimed.title')}
          description={t('error.creditsClaimed.description')}
          button={{ text: t('error.creditsClaimed.button'), onClick: onClose }}
        />
      );
    case !!error:
      return (
        <RewardsModalLayout
          title={t('error.general.title')}
          description={t('error.general.description')}
          button={{ text: t('error.general.button'), onClick: onClose }}
        />
      );
    default:
      return (
        <RewardsModalLayout
          title={t('success.title')}
          description={t('success.description')}
          button={{ text: t('success.button'), onClick: onStartProspecting }}
        />
      );
  }
};

export const RewardsModal: FC<{
  open: boolean;
  error?: Error;
  onClose: () => void;
}> = ({ open, error, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} containerClass={styles.popupContainer}>
      <>
        <Box className={styles.closeIcon} onClick={onClose}>
          <CloseIcon />
        </Box>
        <RewardsModalContent error={error} onClose={onClose} />
      </>
    </Modal>
  );
};
