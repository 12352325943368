import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router';

import { Permission, usePermissions } from '@/features/common/permissions';
import { useAnalytics } from '@/features/system/analytics';

import { useDocumentMeta } from '@/hooks';

import { useObservableResult } from '@/utils/rx';

import type { IIntegrationEntity } from '../domain';

import type { IntegrationDialogConfig } from './components/IntegrationDialog';
import { useIntegrationsUseCase } from './hooks';

export const useIntegrationsViewModel = () => {
  useDocumentMeta({
    title: 'integrations.title',
    description: 'integrations.description',
  });
  const connectUserHandled = useRef(false);
  const {
    trackIntegrationsPageView,
    trackIntegrationConnectSuccess,
    trackIntegrationConnectError,
  } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();
  const permissions = usePermissions();
  const [integrationDialogConfig, setIntegrationDialogConfig] =
    useState<IntegrationDialogConfig | null>(null);

  const resetIntegrationDialogConfig = (): void => {
    setIntegrationDialogConfig(null);
  };

  const integrationsUseCases = useIntegrationsUseCase();
  const provider = searchParams.get('provider');
  const code = searchParams.get('code');

  const integrations = useObservableResult(() => integrationsUseCases.getIntegrations(), {
    defaultData: [],
  });

  const openRequestDialog = (integration: IIntegrationEntity): void => {
    setIntegrationDialogConfig({
      component: 'RequestDialog',
      integration,
      onClose: resetIntegrationDialogConfig,
      integrationsList: integrations.data,
    });
  };

  const openConnectDialog = (integration: IIntegrationEntity): void => {
    setIntegrationDialogConfig({
      component: 'ConnectDialog',
      integration,
      onClose: resetIntegrationDialogConfig,
      openRequestDialog,
    });
  };

  const openContactAdminDialog = (integration: IIntegrationEntity): void => {
    setIntegrationDialogConfig({
      component: 'ContactAdminDialog',
      integration,
      onClose: resetIntegrationDialogConfig,
    });
  };

  const openUpgradeDialog = (integration: IIntegrationEntity): void => {
    setIntegrationDialogConfig({
      component: 'UpgradeDialog',
      integration,
      onClose: resetIntegrationDialogConfig,
    });
  };

  const openErrorDialog = (integration: IIntegrationEntity, error: Error): void => {
    setIntegrationDialogConfig({
      component: 'ErrorDialog',
      integration,
      error,
      onClose: resetIntegrationDialogConfig,
      onRetry: () => {
        openConnectDialog(integration);
      },
    });
  };

  const handleConnect = (integration: IIntegrationEntity): void => {
    if (integration.id === 'custom') {
      return openRequestDialog(integration);
    }

    // user flow
    if (!permissions.hasPermissions(Permission.CanEditIntegrations)) {
      return openContactAdminDialog(integration);
    }

    // admin flow
    if (
      permissions.hasPermissions(Permission.CanEditIntegrations) &&
      !permissions.hasPermissions(Permission.CanAccessCrmIntegration)
    ) {
      return openUpgradeDialog(integration);
    }

    openConnectDialog(integration);
  };

  useEffect(() => {
    trackIntegrationsPageView();
  }, []);

  useEffect(() => {
    if (!provider || !code || !integrations.data.length || connectUserHandled.current)
      return;

    (async (): Promise<void> => {
      try {
        connectUserHandled.current = true;
        await integrationsUseCases.connectUser(provider, code);
        trackIntegrationConnectSuccess(provider);
        setSearchParams((prevSearchParams) => {
          prevSearchParams.delete('provider');
          prevSearchParams.delete('code');
          return prevSearchParams;
        });
      } catch (error) {
        trackIntegrationConnectError(error instanceof Error ? error.message : 'unknown');
        const integration = integrations.data.find(
          (item) => item.id === provider,
        ) as IIntegrationEntity;

        openErrorDialog(integration, error);
      }
    })();
  }, [provider, code, integrations.data.length]);

  return {
    integrations,
    handleConnect,
    integrationDialogConfig,
    resetIntegrationDialogConfig,
  };
};
