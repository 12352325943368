import { useMemo } from 'react';
import { Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { map } from 'rxjs';
import { array, object, string, TestContext } from 'yup';

import { UserRole } from '@/features/common/account';

import { useObservableResult } from '@/utils/rx';
import { WorkEmailValidationSchema } from '@/utils/validation';

import { useTeamMembersUseCase } from '../../../hooks/useTeamMembersUseCase';

export const useInviteModalFormValidation = (): Resolver<{
  emails: string[];
  role: UserRole;
}> => {
  const teamMembersUseCase = useTeamMembersUseCase();

  const { data: emails } = useObservableResult(
    () =>
      teamMembersUseCase
        .getTeamMembers()
        .pipe(map((members) => members.map(({ email }) => email))),
    {
      defaultData: [],
    },
  );

  return useMemo(() => {
    return yupResolver(
      object({
        emails: array()
          .of(
            WorkEmailValidationSchema.test(
              'emailIsExist',
              'emailIsExist',
              (testedEmail) => {
                return !emails.some((email) => email === testedEmail);
              },
            ).test(
              'dupicateEmailsDetected',
              'duplicateEmailsDetected',
              (testedEmail, context: TestContext) => {
                const testedEmails = context.parent as string[] | undefined;

                if (!testedEmails) return true;

                const testedEmailsPart = testedEmails.slice(
                  0,
                  // @ts-ignore
                  context.options.index as number,
                );

                return testedEmailsPart.every((e) => e !== testedEmail);
              },
            ),
          )
          .required(),
        role: string()
          .oneOf([UserRole.Admin, UserRole.Manager, UserRole.Member, UserRole.Owner])
          .required(),
      }),
    );
  }, [emails]);
};
