import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router';

import './translations/i18n';

import { container } from '@/ioc/container';
import { IocProvider } from '@/ioc/ioc.react';

import { Auth } from '@/features/common/auth';
import { AuthExtensionSync } from '@/features/extensionAuth/ui/components/AuthExtensionSync';
import { ContextualOnboarding } from '@/features/system/contextualOnboarding';
import { AuthIdentityCookieSync } from '@/features/system/cookies';
import { CustomerIO } from '@/features/system/CustomerIO';
import { DatabaseInitializer } from '@/features/system/db';
import { GeoSync } from '@/features/system/geoSync';
import { GlobalErrors } from '@/features/system/globalError';
import { Hotjar } from '@/features/system/Hotjar';
import { Hubspot } from '@/features/system/hubspot/ui/Hubspot';
import { LeadsTracker } from '@/features/system/leadsTracking';
import { Replication } from '@/features/system/replication';
import { SentryTracker, withSentryProfiler } from '@/features/system/sentry';
import { ServerTimeSync } from '@/features/system/serverTime';
import { BaseSync } from '@/features/system/sync';

import { ModalProvider } from '@/components';

import { ObservableResultCacheProvider } from '@/utils/rx';

import { AppRouter } from './router/AppRouter';

import './styles/index.scss';

import { ThemeProvider } from '@/theme';

const App: FC = () => {
  useEffect(() => {
    const nativeSplashScreen = document.getElementById('splash-screen');
    nativeSplashScreen?.parentNode?.removeChild(nativeSplashScreen);
  }, []);

  return (
    <BrowserRouter>
      <IocProvider container={container}>
        <ThemeProvider>
          <ModalProvider>
            <ObservableResultCacheProvider>
              <LeadsTracker />
              <SentryTracker />
              <ServerTimeSync />
              <GeoSync />
              <AuthIdentityCookieSync />
              <AuthExtensionSync />
              <GlobalErrors />
              <ContextualOnboarding />
              <CustomerIO />
              <Hotjar />
              <Auth>
                <DatabaseInitializer>
                  <Replication />
                  <BaseSync>
                    <Hubspot />
                    <AppRouter />
                  </BaseSync>
                </DatabaseInitializer>
              </Auth>
            </ObservableResultCacheProvider>
          </ModalProvider>
        </ThemeProvider>
      </IocProvider>
    </BrowserRouter>
  );
};

export default withSentryProfiler(App);
