import { Container } from 'inversify';

import { WORKSPACE_TYPES } from '@/ioc/types';

import {
  IWorkspaceApiService,
  IWorkspaceDao,
  IWorkspaceRepository,
  IWorkspaceUseCase,
  WorkspaceApiService,
  WorkspaceDao,
  WorkspaceRepository,
} from '@/features/common/workspace';
import WorkspaceUseCase from '@/features/common/workspace/domain/WorkspaceUseCase';
import { type IMigrationService } from '@/features/system/db';
import type { IReplicationService } from '@/features/system/replication';

import { WorkspaceMigrationService } from '../data/db/migrations/workspaceMigrations';
import { WorkspaceCollectionCreator } from '../data/db/WorkspaceCollectionCreator';
import { WorkspaceReplicationService } from '../data/db/WorkspaceReplicationService';
import { ICreditUseCase, ISubscriptionUseCase } from '../domain';
import { CreditUseCase } from '../domain/CreditUseCase';
import { SubscriptionUseCase } from '../domain/SubscriptionUseCase';

export const bindWorkspaceModule = (container: Container): void => {
  container
    .bind<IReplicationService>(WORKSPACE_TYPES.WorkspaceReplicationService)
    .to(WorkspaceReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(WORKSPACE_TYPES.WorkspaceReplicationService);

  container
    .bind<WorkspaceCollectionCreator>('DbCollectionCreator')
    .to(WorkspaceCollectionCreator);

  container
    .bind<IWorkspaceDao>(WORKSPACE_TYPES.WorkspaceDao)
    .to(WorkspaceDao)
    .inSingletonScope();

  container
    .bind<IWorkspaceRepository>(WORKSPACE_TYPES.WorkspaceRepository)
    .to(WorkspaceRepository);

  container
    .bind<IWorkspaceApiService>(WORKSPACE_TYPES.WorkspaceApiService)
    .to(WorkspaceApiService);

  container
    .bind<IWorkspaceUseCase>(WORKSPACE_TYPES.WorkspaceUseCase)
    .to(WorkspaceUseCase);

  container
    .bind<ISubscriptionUseCase>(WORKSPACE_TYPES.SubscriptionUseCase)
    .to(SubscriptionUseCase)
    .inSingletonScope();

  container
    .bind<IMigrationService>(WORKSPACE_TYPES.WorkspaceMigrationService)
    .to(WorkspaceMigrationService);

  container.bind<ICreditUseCase>(WORKSPACE_TYPES.CreditUseCase).to(CreditUseCase);
};
