import { inject, injectable } from 'inversify';
import { distinctUntilChanged, firstValueFrom, map, Observable } from 'rxjs';

import { PERMISSIONS_TYPES, TEAM_MEMBER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { UserRole } from '@/features/common/account';
import { type IPermissionsRepository, Permission } from '@/features/common/permissions';
import type { IWorkspaceEntity, IWorkspaceRepository } from '@/features/common/workspace';
import {
  InvitationStatus,
  ITeamMemberEntity,
  ITeamMemberStateRepository,
} from '@/features/settings';

import type { ITeamMemberUseCase } from './abstractions/ITeamMemberUseCase';
import { TeamMemberInviteFreePlanError } from './errors/TeamMemberInviteFreePlanError';
import { TeamMemberInvitePermissionDeniedError } from './errors/TeamMemberInvitePermissionDeniedError';
import { TeamMemberNotFoundError } from './errors/TeamMemberNotFoundError';
import { SeatsValidator } from './validators/SeatsValidator';

type Member = {
  email: string;
  role: UserRole;
};

@injectable()
export default class TeamMemberUseCase implements ITeamMemberUseCase {
  @inject(TEAM_MEMBER_TYPES.TeamMemberRepository)
  private repository: ITeamMemberStateRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private permissionsRepository: IPermissionsRepository;

  public async assertRoleUpdate(member: Member): Promise<void> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(true),
    );

    const currentMember = workspace.members.find(({ email }) => email === member.email);

    if (!currentMember) {
      throw new TeamMemberNotFoundError();
    }

    if (
      currentMember.role === UserRole.Manager ||
      currentMember.invitationStatus !== InvitationStatus.Accepted
    ) {
      new SeatsValidator({ members: [member], workspace }).assert();
    }
  }

  public assertInvite(members: Member[]): Observable<void> {
    return this.workspaceRepository
      .getCurrentWorkspace(true)
      .pipe(map((workspace) => new SeatsValidator({ members, workspace }).assert()));
  }

  public async assertCanInvite(): Promise<void> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(true),
    );

    const hasPermissionToManageMembers = await firstValueFrom(
      this.permissionsRepository.hasPermissions(Permission.CanInviteTeamMembers),
    );

    if (workspace.subscription.planIsFree) {
      throw new TeamMemberInviteFreePlanError();
    }

    if (!hasPermissionToManageMembers) {
      throw new TeamMemberInvitePermissionDeniedError();
    }
  }

  public async inviteTeamMembers(members: Member[]): Promise<IWorkspaceEntity> {
    return this.repository.inviteTeamMembers(members);
  }

  public deleteTeamMembers(
    payload: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return this.repository.deleteTeamMembers(payload);
  }

  public resendInvitation(member: Member): Promise<IWorkspaceEntity> {
    return this.repository.resendInvitation(member);
  }

  public getTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.workspaceRepository
      .getCurrentWorkspace(true)
      .pipe(map((workspace) => workspace.members));
  }

  public getAcceptedOnlyTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.getTeamMembers().pipe(
      map((members) =>
        members.filter((member) => member.invitationStatus === InvitationStatus.Accepted),
      ),
    );
  }

  public async updateTeamMemberRole(
    payload: Pick<ITeamMemberEntity, 'email' | 'role'>,
  ): Promise<IWorkspaceEntity> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(true),
    );

    const memberToUpdate = workspace.members.find(({ email }) => email === payload.email);

    if (!memberToUpdate) {
      throw new TeamMemberNotFoundError();
    }

    return this.workspaceRepository.updateWorkspace({
      ...workspace,
      members: workspace.members.map((member) => {
        if (member.email === payload.email) {
          member.role = payload.role;
        }
        return member;
      }),
    });
  }

  public async setMemberAccessAllContacts(value: boolean): Promise<void> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(true),
    );

    this.workspaceRepository.updateWorkspace({
      ...workspace,
      memberAccessAllContacts: value,
    });
  }

  public getMemberAccessAllContacts(): Observable<boolean> {
    return this.workspaceRepository.getCurrentWorkspace(true).pipe(
      map((workspace) => workspace.memberAccessAllContacts),
      distinctUntilChanged(),
    );
  }

  public getMemberById(uuid: string): Observable<Nullable<ITeamMemberEntity>> {
    return this.getTeamMembers().pipe(
      map((members) => members.find((member) => member.uuid === uuid)),
    );
  }
}
