import { inject, injectable } from 'inversify';
import { mergeDeepRight } from 'ramda';
import {
  BehaviorSubject,
  distinctUntilChanged,
  EMPTY,
  firstValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

import { ACCOUNT_TYPES, SYNC_TYPES } from '@/ioc/types';

import { NonUniquePhoneNumberError } from '@/features/common/auth';
import { IBaseSyncRepository } from '@/features/system/sync';

import {
  IAccountEntity,
  IAccountRepository,
  IAccountSettingsEntity,
  IProvideGoogleContactsReqEntity,
} from '../domain';

import { IAccountDao } from './db/dao/AccountDao';
import { IAccountServicesApi } from './network/AccountApiServices';
import { COUNTRIES_DC, IAccountDC } from './dataContracts';
import {
  mapAccountDcToEntity,
  mapAccountEntityToDc,
  mapGoogleContactsReqEntityToDC,
  mapOnboardingEntityKey,
} from './mappers';

@injectable()
export default class AccountRepository implements IAccountRepository {
  private emailForAccountCreationBehaviorSubject: BehaviorSubject<string> =
    new BehaviorSubject('');

  private account$: BehaviorSubject<Nullable<IAccountEntity>> = new BehaviorSubject(null);

  constructor(
    @inject(ACCOUNT_TYPES.AccountDao)
    private accountDao: IAccountDao,

    @inject(ACCOUNT_TYPES.AccountApiService)
    private accountServiceApi: IAccountServicesApi,

    @inject(SYNC_TYPES.BaseSyncRepository)
    private baseSyncRepository: IBaseSyncRepository,
  ) {
    this.init().subscribe();

    this.baseSyncRepository.getInvalidateEvents().subscribe(() => {
      this.account$.next(null);
    });
  }

  private init(): Observable<never> {
    return this.accountDao.getCurrent().pipe(
      map((acc: Nullable<IAccountDC>) => {
        return acc ? mapAccountDcToEntity(acc) : null;
      }),
      distinctUntilChanged(),
      tap((account) => {
        this.account$.next(account);
      }),
      switchMap(() => EMPTY),
    );
  }

  public saveEmailForAccountCreation(email: string): void {
    this.emailForAccountCreationBehaviorSubject.next(email);
  }

  public deleteEmailForAccountCreation(): void {
    this.emailForAccountCreationBehaviorSubject.next('');
  }

  public getEmailForAccountCreation(): BehaviorSubject<string> {
    return this.emailForAccountCreationBehaviorSubject;
  }

  public getAccount(): Observable<Nullable<IAccountEntity>> {
    return this.account$.asObservable();
  }

  public syncAccount(email?: string): Promise<void> {
    return firstValueFrom(this.accountServiceApi.syncAccount(email));
  }

  public async setupAccount(account: {
    fullName: string;
    phone: string;
    country: COUNTRIES_DC;
  }): Promise<IAccountEntity> {
    try {
      const response = await firstValueFrom(
        this.accountServiceApi.updateAccount({
          full_name: account.fullName,
          settings: {
            country: account.country,
            phone: account.phone,
          },
        }),
      );

      const entity = mapAccountDcToEntity(response);

      this.account$.next(entity);

      return entity;
    } catch (error) {
      if (error.statusCode === 400) {
        throw new NonUniquePhoneNumberError();
      }

      throw error;
    }
  }

  public async updateAccount(
    accountPatch: DeepPartial<IAccountEntity>,
  ): Promise<IAccountEntity> {
    const account = await firstValueFrom(this.getAccount());
    if (!account) {
      throw new Error('Account is not initialized');
    }
    const updatedAccount = mergeDeepRight(account, accountPatch);

    const result = await this.accountDao.upsert(mapAccountEntityToDc(updatedAccount));
    return mapAccountDcToEntity(result);
  }

  public async updateSettings(settings: Partial<IAccountSettingsEntity>): Promise<void> {
    await firstValueFrom(this.accountServiceApi.updateSettings(settings));
  }

  public async provideContacts(req: IProvideGoogleContactsReqEntity): Promise<void> {
    await firstValueFrom(
      this.accountServiceApi.provideContacts(mapGoogleContactsReqEntityToDC(req)),
    );
  }

  public async deleteAccount(): Promise<void> {
    await firstValueFrom(this.accountServiceApi.deleteAccount());
    this.account$.next(null);
  }

  public async completeOnboardingStep(
    steps: Array<keyof IAccountEntity['settings']['onboarding']>,
  ): Promise<void> {
    const account = this.account$.getValue();

    if (account) {
      const needToUpdate = steps.some((step) => !account.settings.onboarding[step]);
      if (!needToUpdate) return;
    }

    const onboarding = steps.reduce((o, step) => {
      o[mapOnboardingEntityKey(step)] = true;
      return o;
    }, {});

    await firstValueFrom(
      this.accountServiceApi.updateSettings({
        onboarding,
      }),
    );
  }
}
