import { useTranslation } from 'react-i18next';
import { catchError, map, throwError } from 'rxjs';

import { useInjection } from '@/ioc';
import { PLANS_TYPES } from '@/ioc/types';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { useAppLogger } from '@/features/system/logger';

import {
  AnalyticsIcon,
  HelpCenterIcon,
  HubIcon,
  KeyIcon,
  PageInfoIcon,
  PersonIcon,
  VerifiedUserIcon,
} from '@/components/Icons';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { GroupedProductMetadata } from '../../domain';
import type { IPlansUseCase } from '../../domain/abstractions/IPlansUseCase';
import type { ProductMetadata } from '../../domain/entities/GroupedProductMetadata';
import {
  LimitedByQuantity,
  PlanFeatureCategory,
  PlanFeatureLevelSupport,
  type PlanFeaturesMetadata,
  Unlimited,
} from '../types';
import {
  billingCycleDependent,
  comingSoon,
  fullySupported,
  limittedByQuantity,
  unlimitted,
  unsupported,
} from '../utils';

type NewPlansMetadata = {
  plansMetadata: GroupedProductMetadata;
  plansFeatureMetadata: PlanFeaturesMetadata;
};

type UseNewPlansMetadata = (params: {
  seats: number;
  billingCycle: BillingCycle;
}) => ObservableResult<NewPlansMetadata>;

type FeaturesSupport = {
  [key in PlanType]?: PlanFeatureLevelSupport;
};

type FeatureMapper = (productMetadata: ProductMetadata) => PlanFeatureLevelSupport;

function assertMetadata<T>(value: Nullable<T>): asserts value is T {
  if (value === null || value === undefined) {
    throw new Error('Plan metadata is not defined');
  }
}

function mapFeature(
  productsMetadata: GroupedProductMetadata,
  mapper: FeatureMapper,
  override: FeaturesSupport,
): FeaturesSupport {
  const featuresSupport: FeaturesSupport = {};

  const entries = Object.entries(productsMetadata);

  for (const [planType, metadata] of entries) {
    assertMetadata(metadata);
    featuresSupport[planType] = mapper(metadata);
  }

  return Object.assign(featuresSupport, override);
}

function mapAmountOfCredits(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = (metadata) => {
    function mapCredit(limit: number | 'unlimited'): LimitedByQuantity | Unlimited {
      return limit === 'unlimited' ? unlimitted() : limittedByQuantity({ limit });
    }
    return billingCycleDependent({
      [BillingCycle.Monthly]: mapCredit(metadata.variants.monthly.fullCredits),
      [BillingCycle.Yearly]: mapCredit(metadata.variants.annually.fullCredits),
    });
  };

  return mapFeature(productsMetadata, mapper, {});
}

function mapExportCredits(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = () => {
    return unlimitted();
  };

  return mapFeature(productsMetadata, mapper, {});
}

function mapLinkedinExt(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = () => {
    return fullySupported();
  };

  return mapFeature(productsMetadata, mapper, {});
}

function mapUniversalExt(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = () => {
    return fullySupported();
  };

  return mapFeature(productsMetadata, mapper, {});
}

function mapDashboard(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = () => {
    return fullySupported();
  };

  return mapFeature(productsMetadata, mapper, {});
}

function mapTopUpCredits(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = () => unsupported();

  return mapFeature(productsMetadata, mapper, {
    [PlanType.Free]: unsupported(),
    [PlanType.Pro]: fullySupported(),
    [PlanType.ProPlus]: fullySupported(),
    [PlanType.Unlimited]: unlimitted(),
    [PlanType.Custom]: unlimitted(),
  });
}

function mapBulkSave(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const mapper: FeatureMapper = (metadata) => {
    return metadata.features.includes('bulk_save') ? fullySupported() : unsupported();
  };
  return mapFeature(productsMetadata, mapper, {
    [PlanType.Custom]: fullySupported(),
  });
}

function mapEnrichCsv(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const featureMapper: FeatureMapper = (metadata) => {
    return metadata.features.includes('csv_enrichment')
      ? fullySupported()
      : unsupported();
  };

  return mapFeature(productsMetadata, featureMapper, {
    [PlanType.Custom]: fullySupported(),
  });
}

function mapListManagement(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const featureMapper: FeatureMapper = () => fullySupported();
  return mapFeature(productsMetadata, featureMapper, {});
}

function mapTagManagement(productsMetadata: GroupedProductMetadata): FeaturesSupport {
  const featureMapper: FeatureMapper = () => fullySupported();
  return mapFeature(productsMetadata, featureMapper, {});
}

function mapExportToCsv(planGroups: GroupedProductMetadata): FeaturesSupport {
  const featureMapper: FeatureMapper = (metadata) => {
    return metadata.features.includes('data_export') ? fullySupported() : unsupported();
  };

  return mapFeature(planGroups, featureMapper, {
    [PlanType.Custom]: fullySupported(),
  });
}

function mapSaveToCrm(planGroups: GroupedProductMetadata): FeaturesSupport {
  const featureMapper: FeatureMapper = (metadata) => {
    return metadata.features.includes('crm_integration')
      ? fullySupported()
      : unsupported();
  };

  return mapFeature(planGroups, featureMapper, {
    [PlanType.Custom]: fullySupported(),
  });
}

function mapSaveToCrmCommingSoon(
  productsMetadata: GroupedProductMetadata,
): FeaturesSupport {
  const featureMapper: FeatureMapper = (metadata) => {
    return metadata.features.includes('crm_integration') ? comingSoon() : unsupported();
  };

  return mapFeature(productsMetadata, featureMapper, {
    [PlanType.Custom]: comingSoon(),
  });
}

export const useNewPlansMetadata: UseNewPlansMetadata = ({ seats, billingCycle }) => {
  const { t } = useTranslation('plans');
  const plansUseCase = useInjection<IPlansUseCase>(PLANS_TYPES.PlansUseCase);
  const appLogger = useAppLogger();

  function getFeatureCategoryTitle(category: PlanFeatureCategory): string {
    return t(`features.categories.${category}`);
  }

  function getFeatureTitle(feature: string): string {
    return t(`features.${feature}.title`);
  }

  function getFeatureHint(feature: string): string {
    return t(`features.${feature}.hint`);
  }

  return useObservableResult(
    () =>
      plansUseCase.getGroupedProductMetadata({ seats, billingCycle }).pipe(
        map((productsMetadata) => {
          return {
            plansMetadata: productsMetadata,
            plansFeatureMetadata: {
              [PlanFeatureCategory.General]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.General),
                icon: <PageInfoIcon />,
                features: [
                  {
                    title: getFeatureTitle('amountOfCredits'),
                    hint: getFeatureHint('amountOfCredits'),
                    supportByPlan: mapAmountOfCredits(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('exportCredits'),
                    hint: getFeatureHint('exportCredits'),
                    supportByPlan: mapExportCredits(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('linkedinExt'),
                    hint: getFeatureHint('linkedinExt'),
                    supportByPlan: mapLinkedinExt(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('universalExt'),
                    hint: getFeatureHint('universalExt'),
                    supportByPlan: mapUniversalExt(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('dashboard'),
                    hint: getFeatureHint('dashboard'),
                    supportByPlan: mapDashboard(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('topUpCredits'),
                    hint: getFeatureHint('topUpCredits'),
                    supportByPlan: mapTopUpCredits(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('bulkSave'),
                    hint: getFeatureHint('bulkSave'),
                    supportByPlan: mapBulkSave(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('enrichCsv'),
                    hint: getFeatureHint('enrichCsv'),
                    supportByPlan: mapEnrichCsv(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.ContactManagement]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.ContactManagement),
                icon: <PersonIcon />,
                features: [
                  {
                    title: getFeatureTitle('listManagement'),
                    hint: getFeatureHint('listManagement'),
                    supportByPlan: mapListManagement(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('tagManagement'),
                    hint: getFeatureHint('tagManagement'),
                    supportByPlan: mapTagManagement(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('exportToCsv'),
                    hint: getFeatureHint('exportToCsv'),
                    supportByPlan: mapExportToCsv(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('saveToCrm'),
                    hint: getFeatureHint('saveToCrm'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('saveToSalesEngagementTool'),
                    hint: getFeatureHint('saveToSalesEngagementTool'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.CrmIntegrations]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.CrmIntegrations),
                icon: <HubIcon />,
                features: [
                  {
                    title: getFeatureTitle('salesforce'),
                    hint: getFeatureHint('salesforce'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('hubspot'),
                    hint: getFeatureHint('hubspot'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('zohoCrm'),
                    hint: getFeatureHint('zohoCrm'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('pipedrive'),
                    hint: getFeatureHint('pipedrive'),
                    supportByPlan: mapSaveToCrmCommingSoon(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('zapier'),
                    hint: getFeatureHint('zapier'),
                    supportByPlan: mapSaveToCrmCommingSoon(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('outreach'),
                    hint: getFeatureHint('outreach'),
                    supportByPlan: mapSaveToCrmCommingSoon(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('salesloft'),
                    hint: getFeatureHint('salesloft'),
                    supportByPlan: mapSaveToCrmCommingSoon(productsMetadata),
                  },
                  {
                    title: getFeatureTitle('customIntegration'),
                    hint: getFeatureHint('customIntegration'),
                    supportByPlan: mapSaveToCrm(productsMetadata),
                  },
                ],
              },
              [PlanFeatureCategory.Analytics]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Analytics),
                icon: <AnalyticsIcon />,
                features: [
                  {
                    title: getFeatureTitle('usageAnalytics'),
                    hint: getFeatureHint('usageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('teamUsageAnalytics'),
                    hint: getFeatureHint('teamUsageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Compliance]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Compliance),
                icon: <VerifiedUserIcon />,
                features: [
                  {
                    title: getFeatureTitle('gdprCompliant'),
                    hint: getFeatureHint('gdprCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('ccpaCompliant'),
                    hint: getFeatureHint('ccpaCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.SSO]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.SSO),
                icon: <KeyIcon />,
                features: [
                  {
                    title: getFeatureTitle('google'),
                    hint: getFeatureHint('google'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('microsoft365'),
                    hint: getFeatureHint('microsoft365'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('okta'),
                    hint: getFeatureHint('okta'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.ProPlus]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('saml'),
                    hint: getFeatureHint('saml'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.ProPlus]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Support]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Support),
                icon: <HelpCenterIcon />,
                features: [
                  {
                    title: getFeatureTitle('helpCenter'),
                    hint: getFeatureHint('helpCenter'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('emailSupport'),
                    hint: getFeatureHint('emailSupport'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('liveChat'),
                    hint: getFeatureHint('liveChat'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.ProPlus]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('onboarding'),
                    hint: getFeatureHint('onboarding'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.ProPlus]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('dedicatedSupport'),
                    hint: getFeatureHint('dedicatedSupport'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.ProPlus]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Custom]: fullySupported(),
                    },
                  },
                ],
              },
            },
          } satisfies NewPlansMetadata;
        }),
        catchError((error) => {
          appLogger.error(error);
          return throwError(() => error);
        }),
      ),
    {
      deps: [seats, billingCycle],
    },
  );
};
