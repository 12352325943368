import { DomainError } from '@/features/common/error';

export class TeamMemberInvitaitionRequireChargeError extends DomainError {
  name: string;

  metadata?: {
    limit: number;
  };

  constructor(metadata?: { limit: number }) {
    super('Team member invitation require charge');
    this.name = 'TeamMemberInvitaitionRequireChargeError';
    this.metadata = metadata;
  }
}
