import { UserRole } from '@/features/common/account';
import { IWorkspaceEntity, WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { InvitationStatus } from '@/features/settings/features/teamManagement';

import { TeamMemberInvitaitionRequireChargeError } from '../errors/TeamMemberInvitaitionRequireChargeError';
import { TeamMemberInviteGiftPlanLimitationError } from '../errors/TeamMemberInviteGiftPlanLimitationError';

type Member = Pick<IWorkspaceEntity['members'][0], 'email' | 'role'>;

export class SeatsValidator {
  private readonly members: Member[];
  private readonly workspace: IWorkspaceEntity;

  constructor(params: { members: Member[]; workspace: IWorkspaceEntity }) {
    this.members = params.members;
    this.workspace = params.workspace;
  }

  private getIsGiftPlan(): boolean {
    return this.workspace.subscription.isGift;
  }

  private getIsFixedPricePlan(): boolean {
    return this.workspace.subscription.planIsPriceFixed;
  }

  private getNeededSeats(): number {
    return this.members.filter(({ role }) => role !== UserRole.Manager).length;
  }

  private getUsedSeats(): number {
    return this.workspace.billableMembersCount;
  }

  private getReservedSeats(): number {
    return this.workspace.members.filter(
      (m) =>
        m.invitationStatus === InvitationStatus.Pending && m.role !== UserRole.Manager,
    ).length;
  }

  private totalPaidSeats(): number {
    if (this.getIsFixedPricePlan()) {
      return this.workspace.subscription.planPaidMembersLimit ?? Number.POSITIVE_INFINITY;
    }

    return this.workspace.subscription.paidMembersCount;
  }

  private totalTheroticSeats(): number {
    return this.workspace.subscription.planPaidMembersLimit ?? Number.POSITIVE_INFINITY;
  }

  assert(): void {
    const neededSeats = this.getNeededSeats();

    if (neededSeats === 0) return;

    const usedSeats = this.getUsedSeats();
    const reservedSeats = this.getReservedSeats();
    const totalPaidSeats = this.totalPaidSeats();
    const totalTheoreticSeats = this.totalTheroticSeats();

    const availableTheoreticSeats = Math.max(
      totalTheoreticSeats - usedSeats - reservedSeats,
      0,
    );

    if (neededSeats > availableTheoreticSeats) {
      throw new WorkspaceSeatsLimitError({ limit: totalTheoreticSeats });
    }

    const availablePaidSeats = Math.max(totalPaidSeats - usedSeats - reservedSeats, 0);

    if (neededSeats > availablePaidSeats) {
      if (this.getIsGiftPlan())
        throw new TeamMemberInviteGiftPlanLimitationError({ limit: totalPaidSeats });

      throw new TeamMemberInvitaitionRequireChargeError({ limit: totalPaidSeats });
    }
  }
}
