import { Container } from 'inversify';

import { REWARDS_TYPES } from '@/ioc/types';

import {
  type IRewardsApiService,
  RewardsApiService,
} from './data/network/RewardsApiService';
import { RewardsRepository } from './data/RewardsRepository';
import type { IRewardsRepository } from './domain/abstractions/IRewardsRepository';
import type { IRewardsUseCase } from './domain/abstractions/IRewardsUseCase';
import { RewardsUseCase } from './domain/RewardsUseCase';

export const bindRewardsModule = (container: Container): void => {
  container
    .bind<IRewardsApiService>(REWARDS_TYPES.RewardsApiService)
    .to(RewardsApiService)
    .inSingletonScope();
  container
    .bind<IRewardsRepository>(REWARDS_TYPES.RewardsRepository)
    .to(RewardsRepository)
    .inSingletonScope();
  container.bind<IRewardsUseCase>(REWARDS_TYPES.RewardsUseCase).to(RewardsUseCase);
};
