import { ChangeEvent, useContext, useState } from 'react';

import { useContactUseCase } from '@/features/common/contact';
import {
  type IContactListEntity,
  useContactListSelectOptions,
  useCreateContactList,
} from '@/features/common/contactList';
import { useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useSearch } from '@/hooks/useSearch';

import { ContactsContext } from '../../context';

type ContactListId = IContactListEntity['uuid'];

type Option = {
  label: string;
  value: ContactListId;
};

type UseListMoveActionViewModel = () => {
  search: {
    value: string;
    onClear: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  options: Option[];
  value: ContactListId;
  selectList: (contactListId: ContactListId) => void;
  createList: () => Promise<void>;
  moveList: () => Promise<void>;
  exitMenu: () => void;
  disabled: boolean;
  applyDisabled: boolean;
  searchIsPartiallyMatched: boolean;
  showCreateListButton: boolean;
};

const getIsStringsPartiallyMatched = (str1: string, str2: string): boolean => {
  if (!str1 || !str2) return false;

  if (str1 === str2) return false;

  return str1.toLowerCase().includes(str2.toLowerCase());
};

export const useListMoveActionViewModel: UseListMoveActionViewModel = () => {
  const logger = useAppLogger();
  const search = useSearch();

  const { trackContactAddToList } = useAnalytics();

  const contactUseCase = useContactUseCase();

  const { selectedContacts } = useContext(ContactsContext);

  const [value, setValue] = useState<ContactListId>('');

  const options: Option[] = useContactListSelectOptions();

  const createContactList = useCreateContactList();

  const disabled = selectedContacts.length === 0;
  const searchIsFullMatched = options.some(
    (option) => option.label.toLowerCase() === search.value.toLowerCase(),
  );
  const searchIsPartiallyMatched =
    options.some((option) => getIsStringsPartiallyMatched(option.label, search.value)) &&
    !searchIsFullMatched;
  const applyDisabled = !value || disabled;

  const selectList = (contactListId: ContactListId): void => {
    setValue(contactListId);
  };

  const moveList = async (contactListId: ContactListId = value): Promise<void> => {
    try {
      await contactUseCase.moveToList(contactListId, selectedContacts);

      trackContactAddToList();
    } catch (error) {
      logger.error(error);
    }
  };

  const createList = async (): Promise<void> => {
    const trimmedContactListName = search.value.trim();

    if (!trimmedContactListName.length) return;

    const contactList = await createContactList(trimmedContactListName);

    selectList(contactList.uuid);

    await moveList(contactList.uuid);

    search.handleSearchClear();
  };

  const exitMenu = (): void => {
    selectList('');
  };

  return {
    search: {
      value: search.value,
      onClear: search.handleSearchClear,
      onChange: (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > 30) {
          return;
        }
        search.handleSearchChange(event);
      },
    },
    options,
    value,
    selectList,
    createList,
    moveList,
    disabled,
    applyDisabled,
    searchIsPartiallyMatched,
    showCreateListButton: Boolean(search.value && !searchIsFullMatched),
    exitMenu,
  };
};
