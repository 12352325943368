import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import HttpClient from '@/features/system/network/HttpClient';

export interface IRewardsApiService {
  claimCredits: () => Observable<boolean>;
}

@injectable()
export class RewardsApiService implements IRewardsApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private readonly httpClient: HttpClient;

  public claimCredits(): Observable<boolean> {
    return this.httpClient
      .post<boolean>('api/v1/billing/email-credits', {})
      .pipe(map((response) => response.data));
  }
}
