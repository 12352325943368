import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanType } from '@/features/common/billing';
import { ProductPrice } from '@/features/plans/domain';
import {
  BillingCycleContext,
  PlanActionResolverContext,
} from '@/features/plans/ui/contexts';
import { useProductMetadata } from '@/features/plans/ui/hooks';
import { PlanAction } from '@/features/plans/ui/types';

import { formatCurrency } from '@/utils/formatCurrency';

type PlanCardViewModel = {
  name: string;
  isCurrent: boolean;
  price: { value: string; clarification?: string; underpriceText?: string };
  action: PlanAction;
  onAction?: () => void;
};

function usePrice(price: ProductPrice): PlanCardViewModel['price'] {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');

  if (price === 0) {
    return {
      value: formatCurrency({
        amount: price,
        currency: 'USD',
        locale: language,
        maximumFractionDigits: 0,
      }),
    };
  }

  if (price === 'contact-sales') {
    return {
      value: t('prices.contactTitle'),
      underpriceText: t('prices.contactSubtitle'),
    };
  }

  if (typeof price === 'number') {
    return {
      value: formatCurrency({
        amount: price,
        currency: 'USD',
        locale: language,
        maximumFractionDigits: 0,
      }),
      clarification: t('prices.rateSmall'),
    };
  }

  return {
    value: formatCurrency({
      amount: price.price,
      currency: 'USD',
      locale: language,
      maximumFractionDigits: 0,
    }),
    clarification: t('prices.rateSmall'),
  };
}

export function usePlanCardViewModel(params: { variant: PlanType }): PlanCardViewModel {
  const productMetadata = useProductMetadata(params.variant);
  const billingCycle = useContext(BillingCycleContext);
  const actionResolver = useContext(PlanActionResolverContext);

  const price = usePrice(productMetadata.variants[billingCycle].price);
  const action = actionResolver.resolve({
    planType: params.variant,
    billingCycle,
    originalProduct: productMetadata.variants[billingCycle].originalProduct,
  });

  return {
    name: productMetadata.name,
    price,
    isCurrent: productMetadata.variants[billingCycle].current ?? false,
    action,
    onAction: (): void => {
      actionResolver.onAction({
        planType: params.variant,
        billingCycle,
        action,
        originalProduct: productMetadata.variants[billingCycle].originalProduct,
      });
    },
  };
}
