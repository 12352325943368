import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';

import { REWARDS_TYPES } from '@/ioc/types';

import type { IRewardsRepository } from '../domain/abstractions/IRewardsRepository';

import type { IRewardsApiService } from './network/RewardsApiService';

@injectable()
export class RewardsRepository implements IRewardsRepository {
  @inject(REWARDS_TYPES.RewardsApiService)
  private apiService: IRewardsApiService;

  async claimCredits(): Promise<boolean> {
    return firstValueFrom(this.apiService.claimCredits());
  }
}
