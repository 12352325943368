import { inject } from 'inversify';

import { REWARDS_TYPES } from '@/ioc/types';

import type { IRewardsRepository } from './abstractions/IRewardsRepository';
import type { IRewardsUseCase } from './abstractions/IRewardsUseCase';
import { RewardsAlreadyClaimedError } from './errors/RewardsAlreadyClaimedError';

export class RewardsUseCase implements IRewardsUseCase {
  @inject(REWARDS_TYPES.RewardsRepository)
  private rewardsRepository: IRewardsRepository;

  async claimCredits(): Promise<boolean> {
    const isClaimed = await this.rewardsRepository.claimCredits();
    if (!isClaimed) {
      throw new RewardsAlreadyClaimedError();
    }
    return isClaimed;
  }
}
