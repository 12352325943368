import { DomainError } from '@/features/common/error';

export class WorkspaceSeatsLimitError extends DomainError {
  metadata?: {
    limit: number;
  };

  constructor(metadata?: { limit: number }) {
    super('Workspace seats limit reached');
    this.name = 'WorkspaceSeatsLimitError';
    this.metadata = metadata;
  }
}
