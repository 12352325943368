import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, CONTACT_LIST_TYPES, PERMISSIONS_TYPES } from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';
import {
  ContactListSortSchema,
  IContactListEntity,
  IContactListRepository,
  IContactListUseCase,
} from '@/features/common/contactList';
import { IPermissionsRepository, Permission } from '@/features/common/permissions';

import { ContactNameAlreadyExistError } from './errors';

@injectable()
export default class ContactListUseCase implements IContactListUseCase {
  @inject(CONTACT_LIST_TYPES.ContactListRepository)
  private readonly contactListRepository: IContactListRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private readonly permissionsRepository: IPermissionsRepository;

  private async validateContactListUniqueness(payload: {
    name: string;
    uuid?: string;
  }): Promise<void> {
    const contacts = await firstValueFrom(
      this.contactListRepository.getContactListByQuery(),
    );

    const hasDuplicates = contacts.some(
      (contact) => contact.name === payload.name && contact.uuid !== payload?.uuid,
    );

    if (hasDuplicates) throw new ContactNameAlreadyExistError();
  }

  public getDefaultContactList(): Observable<Nullable<IContactListEntity>> {
    return this.contactListRepository.getDefaultContactList();
  }

  public getContactListByQuery(
    payload: {
      nameReg?: string;
      created_by?: string;
      sortSchema?: ContactListSortSchema;
    } = {},
  ): Observable<IContactListEntity[]> {
    return this.permissionsRepository.hasPermissions(Permission.CanAccessAllContact).pipe(
      switchMap((hasPermission) => {
        if (!hasPermission) {
          return this.accountRepository.getAccount().pipe(
            filter((account) => !!account),
            switchMap((account) => {
              return this.contactListRepository.getContactListByQuery({
                ...payload,
                created_by: account.uuid,
              });
            }),
          );
        }

        return this.contactListRepository.getContactListByQuery(payload);
      }),
    );
  }

  public getContactListById(id: string): Observable<Nullable<IContactListEntity>> {
    return this.contactListRepository.getContactListById(id);
  }

  public async createContactList(payload: { name: string }): Promise<IContactListEntity> {
    const account = await firstValueFrom(this.accountRepository.getAccount());
    await this.validateContactListUniqueness({ name: payload.name });

    return this.contactListRepository.createContactList({
      ...payload,
      created_by: account?.uuid ?? '',
    });
  }

  public async updateContactList(payload: {
    uuid: string;
    name: string;
  }): Promise<IContactListEntity> {
    await this.validateContactListUniqueness(payload);

    return this.contactListRepository.updateContactList(payload);
  }

  public deleteContactList(uuid: string): Promise<boolean> {
    return this.contactListRepository.deleteContactList(uuid);
  }
}
