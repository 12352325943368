import { useMemo, useRef } from 'react';

import type { InviteForm } from '../types';

export function useGetValidatedOnlyEmails(emails: string[], form: InviteForm): string[] {
  // we need a validation cache as validating is async, and we want to show old valid emails while validation is happening
  const validEmailsCache = useRef<string[]>([]);

  const isValidating = form.formState.isValidating;
  const emailErrors = form.formState.errors.emails;

  return useMemo(() => {
    if (isValidating) return validEmailsCache.current;

    const validEmails = emails.filter(
      (_email, index) => !form.formState.errors.emails?.[index],
    );

    validEmailsCache.current = validEmails;
    return validEmails;
  }, [emails, emailErrors, isValidating]);
}
