import { useContext } from 'react';

import type { PlanType } from '@/features/common/billing';

import { ProductMetadata } from '../../domain/entities/GroupedProductMetadata';
import { PlansMetadataContext } from '../contexts';

export const useProductMetadata = (planType: PlanType): ProductMetadata => {
  const plansMetadata = useContext(PlansMetadataContext);
  const metadata = plansMetadata[planType];
  if (!metadata) throw new Error(`Plan metadata not found for ${planType}`);
  return metadata;
};
