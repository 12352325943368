import { BillingCycle } from '@/features/common/billing';

import type { ProductCredits } from '../entities/GroupedProductMetadata';

import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class ProProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    const items: string[] = [];
    const product = this.getProduct();
    const bulkCredits: ProductCredits = this.getCreditsCount(product, 'bulk');
    const cycleLabel = this.billingCycle == BillingCycle.Yearly ? 'year' : 'month';

    if (this.getIsFeatureEnabled('data_export')) {
      items.push('Data exports');
    }

    if (this.getIsFeatureEnabled('team_member_management')) {
      items.push('Invite, collaborate with, and manage team members');
    }

    if (this.getIsFeatureEnabled('bulk_save') && bulkCredits) {
      items.push(
        `${bulkCredits} bulk credits / ${cycleLabel} (bulk enrichment & bulk save)`,
      );
    }

    return {
      title: 'What’s included:',
      items,
    };
  }
  getDescription(): string {
    return 'The tools your team needs to keep on growing';
  }
  getIsCreditCardRequired(): boolean {
    return true;
  }
  getIsMostPopular(): boolean {
    return false;
  }
}
